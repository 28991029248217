import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {Loader, ProcedureCards} from '../../../components';
import {FORM_ROUTE, PROCEDURES_LIST} from '../../../constants';
import './styles.scss';
import {CustomDispatch} from '../../../helpers';
import {getProceduresRequest} from '../../../redux/slicers/general';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {replaceValInString} from '../../../utils';
import {
  getPayloadSuccess,
  submitFormRequest,
} from '../../../redux/slicers/form';
import {Button} from 'antd';
import {ClipLoader} from 'react-spinners';
import {removeFormData} from '../../../redux/slicers/offlineData';

const Dashboard = () => {
  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  //REDUX DATA
  const {procedures} = useSelector(state => state.general);
  const {isOnline, formsData} = useSelector(state => state.offline);
  const {data} = useSelector(state => state.user);

  //CUSTOM DISPATCH
  const [getProcedures, proceduresLoader] =
    CustomDispatch(getProceduresRequest);
  const [submitForm, submitFormLoader] = CustomDispatch(submitFormRequest);

  //HANDLERS
  const handleCardClick = id => {
    navigate(replaceValInString(FORM_ROUTE, {':id': id}));
    dispatch(getPayloadSuccess({}));
  };
  const formKeys = Object.keys(formsData);

  // const submitOfflineData = async () => {
  //   if (isOnline && formsData && !submitFormLoader) {
  //     setIsLoading(true); // Set loading state to true
  //     // Only run if not already submitting

  //     for (const key of formKeys) {
  //       const formData = formsData[key];

  //       console.log(key, formsData, 'submitting form data for key');
  //       try {
  //         await submitForm({
  //           payload: formData,
  //           pathParams: key,
  //           logic(res) {
  //             console.log(key, res, 'submitting form data for key');
  //             dispatch(removeFormData(key)); // Remove form data after successful submission
  //           },
  //           queryParams: {
  //             notSaveLocal: true,
  //           },
  //         });
  //       } catch (error) {
  //         console.error(`Error submitting form data for ${key}:`, error);
  //         // Handle error here (retry logic, logging, etc.)
  //       }
  //     }
  //     setIsLoading(false); // Set loading state
  //   }
  // };

  console.log(data, 'data');
  //HOOKS
  useEffect(() => {
    if (data?.id) getProcedures();
  }, [data]);

  return (
    <section className="dashboard mt-[69px] ">
      {/* {isLoading && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-[#D9D9D933] backdrop-blur-sm flex flex-col items-center justify-center gap-3 px-4">
          <ClipLoader color={'#004078'} loading={true} size={150} />
          <p className="text-[32px] font-medium text-[#004078] text-center">
            Submitting offline data, please wait...
          </p>
        </div>
      )} */}
      <div className="h-[50px] bg-grey-100 border-b-[1px] border-solid border-grey-200 justify-end flex items-center px-2">
        {/* <Button disabled={formKeys?.length === 0} onClick={submitOfflineData}>
          Sync
        </Button> */}
      </div>
      {proceduresLoader ? (
        <Loader height="80vh" />
      ) : (
        <div className="dashboard-content px-[20px] md:px-[40px] py-[20px]">
          <p className="font-arial font-medium text-xl text-textColor-100 mb-[30px]">
            Available Procedures
          </p>
          <Grid
            container
            columnSpacing={{xs: '40px', xl: '90px'}}
            className="items-stretch"
            rowSpacing={{xs: '40px', xl: '60px'}}>
            {procedures?.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <ProcedureCards
                  title={item?.title}
                  description={item?.description}
                  status={item?.status}
                  onClick={() => handleCardClick(item?.id)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </section>
  );
};

export default Dashboard;
