import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import React, {useEffect, useState} from 'react';
import './styles.scss';
import {inputFieldRule, toastAlert, toTitleCase} from '../../../utils';
import MembersTable from '../membersTable';
import {CustomDispatch} from '../../../helpers';
import {
  addMembersRequest,
  deleteMembersRequest,
  editMembersRequest,
  getMembersRequest,
  getRolesRequest,
} from '../../../redux/slicers/members';
import {useSelector} from 'react-redux';
import {getCarriersRequest} from '../../../redux/slicers/general';
import PhoneInput from 'react-phone-input-2';

const MemberManagementModal = ({open, handleClose}) => {
  //STATES
  const [isSendWelcomeNotice, setIsSendWelcomeNotice] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);

  //REDUX DATA
  const roles = useSelector(state => state.members.roles);
  const user = useSelector(state => state.user.data);
  const {companies} = useSelector(state => state.user);
  const {carriers} = useSelector(state => state.general);

  //CUSTOM DISPATCH
  const [getMembers, membersLoader] = CustomDispatch(getMembersRequest);
  const [getRoles, rolesLoader] = CustomDispatch(getRolesRequest);
  const [addMember, addMemberLoader] = CustomDispatch(addMembersRequest);
  const [editMember, editMemberLoader] = CustomDispatch(editMembersRequest);
  const [deleteMember, deleteMemberLoader] =
    CustomDispatch(deleteMembersRequest);
  const [getCarriers, carriersLoader] = CustomDispatch(getCarriersRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const isSubmitDisabled =
    !form.isFieldsTouched(true) ||
    form.getFieldsError().filter(({errors}) => errors.length).length > 0;
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Please Enter a valid email.',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const [editUserForm] = Form.useForm();

  //HANDLERS
  const handleFinish = vals => {
    console.log(vals);
    const payload = {
      ...vals,
      username: vals.username.trim().toLowerCase(),
    };
    addMember({
      // payload: {company: user.companyId, ...vals},
      payload: payload,
      logic: res => {
        toastAlert('Member addedd successfully');
        form.resetFields();
        setIsSendWelcomeNotice(false);
        getMembers();
      },
    });
  };
  const handleEditFinish = vals => {
    console.log(vals);

    // Create the updatedPayload and conditionally include password if it's not an empty string
    const updatedPayload = {
      ...vals,
      id: selectedDataId, // Only add password if it's not an empty string
    };
    updatedPayload['username'] = vals.username.trim().toLowerCase();
    if (vals.password === '') {
      delete updatedPayload.password;
    }

    editMember({
      payload: updatedPayload,
      pathParams: selectedDataId,
      logic: res => {
        toastAlert('Member updated successfully');
        form.resetFields();
        setIsSendWelcomeNotice(false);
        setIsEdit(false);
        getMembers();
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (open) {
      getMembers();
      getRoles();
      getCarriers();
    }
  }, [open]);

  const handleEdit = data => {
    if (!data) return;
    setSelectedDataId(data?.id);
    setIsEdit(true);
    editUserForm.setFieldsValue({
      first_name: data?.firstName,
      last_name: data?.lastName,
      username: data?.userName?.trim().toLowerCase(),
      email: data?.email,
      password: data?.password,
      phone: data?.phone,
      company: data?.company ?? [],
      email_to_sms_gateway: data?.carrier,
      role: data?.role,
    });
  };

  const handleDelete = id => {
    deleteMember({
      pathParams: id,
      logic(res) {
        toastAlert('Member deleted successfully');
        getMembers();
      },
    });
  };

  const clinics = [
    {
      label: 'Clinic 1',
      value: 1,
    },
    {
      label: 'Clinic 2',
      value: 2,
    },
    {
      label: 'Clinic 3',
      value: 3,
    },
  ];

  return (
    <Modal
      width={750}
      open={open}
      destroyOnClose
      footer={null}
      centered
      className="member-management-modal"
      onCancel={() => {
        handleClose();
        setIsEdit(false);
        setSelectedDataId(null);
      }}
      maskClosable={false}>
      {isEdit ? (
        <div>
          <p className="font-h4 mb-5">Profile Settings</p>
          <Form
            form={editUserForm}
            className="px-2"
            validateMessages={validateMessages}
            onFinish={handleEditFinish}>
            <Row gutter={[50, 10]}>
              <Col span={24}>
                <p className="font-subtext text-[14px]">Personal Information</p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">First Name</p>
                <Form.Item name={'first_name'}>
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Last Name</p>
                <Form.Item name={'last_name'}>
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Email</p>
                <Form.Item
                  name={'email'}
                  rules={[
                    {
                      type: 'email',
                    },
                  ]}>
                  <Input type="email" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Mobile Carrier</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Carrier',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'email_to_sms_gateway'}>
                  <Select
                    options={carriers?.map(carrier => ({
                      label: toTitleCase(carrier.carrier_name),
                      value: carrier.id,
                    }))}
                    loading={carriersLoader}
                    placeholder="Select Carrier"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Phone</p>
                <Form.Item name={'phone'}>
                  <PhoneInput
                    onlyCountries={['us']}
                    disableDropdown
                    country="us"
                    // countryCodeEditable={false}
                    specialLabel=""
                    placeholder="Phone"
                    inputClass="text-[12px] rounded-[6px] h-[32px] w-full px-[11px] bg-[#fff] border border-[#d9d9d9] placeholder:text-[#00000040]"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-subtext text-[12px] mb-1">Account Type</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Account Type',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'role'}>
                  <Select
                    options={roles.map(role => ({
                      label: toTitleCase(role.name),
                      value: role.id,
                    }))}
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Account Type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Company</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'company',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'company'}>
                  <Select
                    mode="multiple"
                    options={companies}
                    maxTagCount={'responsive'}
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Company"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[50, 10]} className="mt-10">
              <Col span={24}>
                <p className="font-subtext text-[14px]">Account Information</p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">User Name</p>
                <Form.Item name={'username'}>
                  <Input placeholder="User Name" autocomplete="off" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">PIN</p>
                <Form.Item name={'password'}>
                  <Input.Password placeholder="PIN" autocomplete="off" />
                </Form.Item>
              </Col>
            </Row>

            <div className="flex items-center justify-end mt-10 gap-3 sm:flex-nowrap flex-wrap">
              <Button
                onClick={() => {
                  setIsEdit(false);
                  setSelectedDataId(null);
                }}
                className="cancel-btn sm:w-[100px] w-full text-textColor-100 uppercase text-[10px]  disabled:bg-transparent bg-transparent rounded-md  hover:bg-transparent ">
                Cancel
              </Button>
              <Button
                htmlType="submit"
                loading={editMemberLoader}
                className="disabled:bg-transparent sm:w-[100px] w-full uppercase text-[10px] bg-blue-300 text-white-100  rounded-md  hover:bg-opacity-70">
                Save
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <>
          <p className="font-h4 mb-5">Member Management</p>
          <Form
            form={form}
            validateMessages={validateMessages}
            onFinish={handleFinish}>
            <Row
              gutter={[10, 0]}
              className="p-2 border-[1px] border-[#D5DFE6] rounded-md max-w-full mr-0 ml-0 m-0">
              <Col span={24}>
                <p className="font-subtext text-[14px] mb-3">
                  New Member Information
                </p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">First Name</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'First Name',
                    isRequired: true,
                  })}
                  shouldUpdate
                  name={'first_name'}>
                  <Input className="text-[12px]" placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Last Name</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Last Name',
                    isRequired: true,
                  })}
                  name={'last_name'}>
                  <Input className="text-[12px]" placeholder="Last Name" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Email</p>
                <Form.Item
                  name={'email'}
                  rules={inputFieldRule({
                    name: 'Email',
                    isRequired: true,
                    isEmail: true,
                  })}>
                  <Input
                    className="text-[12px]"
                    type="email"
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Mobile Carrier</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Carrier',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'email_to_sms_gateway'}>
                  <Select
                    options={carriers?.map(carrier => ({
                      label: toTitleCase(carrier.carrier_name),
                      value: carrier.id,
                    }))}
                    loading={carriersLoader}
                    placeholder="Select Carrier"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Phone</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Phone',
                    isRequired: true,
                  })}
                  name={'phone'}>
                  <PhoneInput
                    onlyCountries={['us']}
                    disableDropdown
                    country="us"
                    // countryCodeEditable={false}
                    specialLabel=""
                    placeholder="Phone"
                    inputClass="text-[12px] rounded-[6px] h-[32px] w-full px-[11px] bg-[#fff] border border-[#d9d9d9] placeholder:text-[#00000040]"
                  />

                  {/* <Input className="text-[12px]" placeholder="Phone" /> */}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Account Type</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Account Type',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'role'}>
                  <Select
                    options={roles.map(role => ({
                      label: toTitleCase(role.name),
                      value: role.id,
                    }))}
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Account Type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Company</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'company',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'company'}>
                  <Select
                    mode="multiple"
                    options={companies}
                    maxTagCount={'responsive'}
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Company"
                  />
                </Form.Item>
              </Col>
              <Col xs={0} sm={24} md={24} lg={24} xl={24}></Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">User Name</p>
                <Form.Item
                  name={'username'}
                  rules={inputFieldRule({
                    name: 'User Name',
                    isRequired: true,
                  })}
                  required>
                  <Input className="text-[12px]" placeholder="User Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">PIN</p>
                <Form.Item
                  name={'password'}
                  rules={inputFieldRule({
                    name: 'PIN',
                    isRequired: true,
                  })}
                  required>
                  <Input.Password className="text-[12px]" placeholder="PIN" />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <Checkbox
                  checked={isSendWelcomeNotice}
                  onChange={() => setIsSendWelcomeNotice(!isSendWelcomeNotice)}>
                  <p className="font-subtext text-[12px]">
                    Send Welcome Notice
                  </p>
                </Checkbox>
                <Form.Item
                  // name={isSendWelcomeNotice ? 'welcomeType' : undefined}
                  shouldUpdate
                  rules={inputFieldRule({
                    name: 'Welcome Type',
                    isRequired: isSendWelcomeNotice,
                    requiredMessage: 'Please select welcome notice type.',
                  })}>
                  <Radio.Group disabled={!isSendWelcomeNotice}>
                    <Space direction="vertical">
                      <Radio value="email" className="font-subtext text-[12px]">
                        <p className="font-subtext text-[12px]">Email</p>
                      </Radio>
                      <Radio value="sms">
                        {' '}
                        <p className="font-subtext text-[12px]">SMS</p>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <div className="flex w-full h-full justify-center items-center">
                  <Button
                    htmlType="submit"
                    loading={addMemberLoader}
                    className="disabled:bg-transparent w-full uppercase text-[10px] bg-blue-300 text-white-100  rounded-md  hover:bg-opacity-70 py-5 flex items-center justify-center">
                    <p className="text-[13px]">Add</p>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          <p className="font-h4 mb-5 mt-5">Members</p>
          <MembersTable
            selected={selected}
            setSelected={setSelected}
            setIsEdit={handleEdit}
            handleDelete={handleDelete}
            deleteLoader={deleteMemberLoader}
            loading={membersLoader}
          />
          <div className="flex items-center justify-end mt-10 gap-3 sm:flex-nowrap flex-wrap">
            <Button
              onClick={handleClose}
              className="cancel-btn sm:w-[100px] w-full text-textColor-100 uppercase text-[10px]  disabled:bg-transparent bg-transparent rounded-md  hover:bg-transparent ">
              Cancel
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MemberManagementModal;
