import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {Loader} from './components';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import CreateStore from './store';
import DataHandler from './services/data-handler';
import PageRoutes from './routes/index';
import reducers from './redux/slicers';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import './App.scss';
import NetworkStatusListener from './components/common/NetworkListener';

function App() {
  const [persistor, setPersistor] = useState(null);
  const [store, setStore] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const storeConfig = CreateStore(reducers, () => {
      DataHandler.setStore(storeConfig);
      setStore(storeConfig);
      setPersistor(persistStore(storeConfig));
      setLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Provider store={store}>
          <PersistGate loading={<Loader />} persistor={persistor}>
            <NetworkStatusListener />
            <Router>
              <ToastContainer />
              <PageRoutes />
            </Router>
          </PersistGate>
        </Provider>
      )}
    </>
  );
}

export default App;
