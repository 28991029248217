import {createSlice} from '@reduxjs/toolkit';

const UserReducer = createSlice({
  name: 'rules',
  initialState: {
    rules: [],
  },
  reducers: {
    getComplianceRulesRequest() {},
    getComplianceRulesSuccess(state, action) {
      state.rules = action.payload;
    },

    createComplianceRuleRequest() {},
    createComplianceRuleSuccess() {},

    editComplianceRuleRequest() {},
    editComplianceRuleSuccess() {},

    deleteComplianceRuleRequest() {},
    deleteComplianceRuleSuccess() {},
  },
});

export const {
  getComplianceRulesRequest,
  getComplianceRulesSuccess,
  createComplianceRuleRequest,
  createComplianceRuleSuccess,
  editComplianceRuleRequest,
  editComplianceRuleSuccess,
  deleteComplianceRuleRequest,
  deleteComplianceRuleSuccess,
} = UserReducer.actions;

export default UserReducer.reducer;
