import React from 'react';
const Home = () => {
  return (
    <section className="home-wrapper">
      <h3>Home</h3>
    </section>
  );
};

export default Home;
