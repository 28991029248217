import _ from 'lodash';

export const manipulateUserData = data => {
  if (_.isEmpty(data)) return {};
  let user = {};
  user['id'] = data?.user?.pk ?? 0;
  user['firstName'] = data?.user?.first_name ?? 0;
  user['lastName'] = data?.user?.last_name ?? '';
  user['name'] = data?.user?.first_name + ' ' + data?.user?.last_name ?? '';
  user['companyId'] = data?.company?.[0] ?? 0;
  user['access_token'] = data?.access ?? '';
  user['refresh_token'] = data?.refresh ?? '';
  user['isAdmin'] = data?.role === 1 ?? false;
  user['role'] = data?.role ?? 0;

  return user;
};

export const manipulateMemberData = data => {
  if (_.isEmpty(data)) return {};
  let member = {};
  member['id'] = data?.id ?? 0;
  member['key'] = data?.id ?? 0;
  member['firstName'] = data?.first_name ?? '';
  member['lastName'] = data?.last_name ?? '';
  member['name'] = data?.first_name + ' ' + data?.last_name ?? '';
  member['userName'] = data?.username ?? '';
  member['level'] = data?.role ?? '';
  member['addedOn'] = data?.created_at ?? '1 / 23 / 23';
  member['password'] = data?.password ?? '';
  member['email'] = data?.email ?? '';
  member['phone'] = data?.phone ?? '';
  member['company'] = data?.company ?? [];
  member['carrier'] = data?.email_to_sms_gateway ?? '';
  member['role'] = data?.role ?? 0;

  return member;
};

export const manipulateMembersList = data => {
  if (_.isEmpty(data)) return [];

  let members = [];
  data?.forEach(item => {
    members.push(manipulateMemberData(item));
  });
  return members;
};

export const manipulateCompanyData = data => {
  if (_.isEmpty(data)) return {};
  let company = {};
  company['label'] = data?.company_name ?? '';
  company['value'] = data?.company ?? 0;

  return company;
};

export const manipulateCompaniesList = data => {
  if (_.isEmpty(data)) return [];

  let companies = [];
  data?.forEach(item => {
    companies.push(manipulateCompanyData(item));
  });
  return companies;
};
