import {fork} from 'redux-saga/effects';
import user from './user';
import general from './general';
import form from './form';
import members from './members';
import rules from './rules';
export default function* root() {
  yield fork(user);
  yield fork(general);
  yield fork(form);
  yield fork(members);
  yield fork(rules);
}
