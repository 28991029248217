import {take, put, call, fork} from 'redux-saga/effects';
import {
  addMembersRequest,
  addMembersSuccess,
  deleteMembersRequest,
  deleteMembersSuccess,
  editMembersRequest,
  editMembersSuccess,
  getMembersRequest,
  getMembersSuccess,
  getRolesRequest,
  getRolesSuccess,
} from '../slicers/members';
import {ALERT_TYPES} from '../../constants';
import {
  ADD_MEMBER,
  callRequest,
  DELETE_MEMBER,
  EDIT_MEMBER,
  GET_MEMBERS,
  GET_ROLES,
} from '../../config/web-service';
import {toastAlert} from '../../utils';
import {manipulateMembersList} from '../../data-manipulator/user';

// GET MEMBERS
function* getMembers() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getMembersRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_MEMBERS,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);

        yield put(
          getMembersSuccess(manipulateMembersList(response.response_data)),
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET ROLES
function* getRoles() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getRolesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_ROLES,
        queryParams: {},
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(getRolesSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// DELETE MEMBER
function* deleteMember() {
  while (true) {
    const {
      payload: {responseCallback, pathParams},
    } = yield take(deleteMembersRequest.type);
    try {
      const response = yield call(callRequest, {
        url: DELETE_MEMBER,
        pathParams: pathParams,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(deleteMembersSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// EDIT MEMBER
function* editMember() {
  while (true) {
    const {
      payload: {responseCallback, pathParams, payload},
    } = yield take(editMembersRequest.type);
    try {
      const response = yield call(callRequest, {
        url: EDIT_MEMBER,
        pathParams,
        payload,
      });
      if (response.response_code === '200') {
        console.log(response, 'responseCallback');
        responseCallback?.(true, response);

        yield put(editMembersSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
// ADD MEMBER
function* addMember() {
  while (true) {
    const {
      payload: {responseCallback, payload},
    } = yield take(addMembersRequest.type);
    try {
      const response = yield call(callRequest, {
        url: ADD_MEMBER,
        payload,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(addMembersSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield fork(getMembers);
  yield fork(getRoles);
  yield fork(addMember);
  yield fork(deleteMember);
  yield fork(editMember);
}
