import {take, put, call, fork} from 'redux-saga/effects';
import {
  getUserCompaniesRequest,
  getUserCompaniesSuccess,
  getUserDataRequest,
  getUserDataSuccess,
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  verifyPinRequest,
} from '../slicers/user';
import {ALERT_TYPES} from '../../constants';
import {
  callRequest,
  GET_COMPANIES,
  GET_MEMBERS,
  USER_LOGIN,
  USER_LOGOUT,
  VERIFY_PIN,
} from '../../config/web-service';
import {toastAlert} from '../../utils';
import {
  manipulateCompaniesList,
  manipulateMemberData,
  manipulateUserData,
} from '../../data-manipulator/user';

// USER LOGIN
function* login() {
  while (true) {
    const {
      payload: {payload, responseCallback},
    } = yield take(loginRequest.type);
    try {
      const response = yield call(callRequest, {
        url: USER_LOGIN,
        payload,
      });
      if (response.response_code === 200) {
        responseCallback?.(true, response);
        yield put(loginSuccess(manipulateUserData(response.response_data)));
      } else {
        responseCallback?.(false, response);
        console.log(response, response.response_data?.non_field_errors?.[0]);
        toastAlert(
          response.response_data?.non_field_errors?.[0],
          ALERT_TYPES.ERROR,
        );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
// USER LOGOUT
function* logout() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(logoutRequest.type);
    try {
      const response = yield call(callRequest, {
        url: USER_LOGOUT,
      });
      yield put(logoutSuccess());
      // if (response.response_code === 200) {
      //   responseCallback?.(true, response);
      // } else {
      //   responseCallback?.(false, response);
      //   response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      // }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
// USER DATA
function* getUserData() {
  while (true) {
    const {
      payload: {pathParams, responseCallback},
    } = yield take(getUserDataRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_MEMBERS,
        pathParams,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(
          getUserDataSuccess(manipulateMemberData(response.response_data)),
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// USER Companies
function* getUserCompanies() {
  while (true) {
    const {
      payload: {queryParams, payload, responseCallback},
    } = yield take(getUserCompaniesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_COMPANIES,
        queryParams,
        payload,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(
          getUserCompaniesSuccess(
            manipulateCompaniesList(response.response_data),
          ),
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//VERIFY USER PIN
function* verifyPin() {
  while (true) {
    const {
      payload: {payload, responseCallback},
    } = yield take(verifyPinRequest.type);
    try {
      const response = yield call(callRequest, {
        url: VERIFY_PIN,
        payload,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield fork(login);
  yield fork(logout);
  yield fork(getUserData);
  yield fork(getUserCompanies);
  yield fork(verifyPin);
}
