import {Button, Modal} from 'antd';
import React from 'react';
import './styles.scss';

const ConfirmationModal = ({
  open,
  handleClose,
  title,
  description,
  onConfirm,
  confirmLoader,
}) => {
  return (
    <Modal
      width={500}
      open={open}
      destroyOnClose
      footer={null}
      centered
      className="confirmation-modal"
      onCancel={() => {
        handleClose();
      }}
      maskClosable={false}>
      <p className="font-h4 mb-5">{title ?? 'Title'}</p>
      <p>{description ?? 'Description'}</p>
      <div className="flex items-center justify-end gap-2 mt-3 ">
        <Button
          loading={confirmLoader}
          onClick={onConfirm}
          className="disabled:bg-transparent sm:w-[100px] w-full uppercase text-[10px] bg-blue-300 text-white-100  rounded-md  hover:bg-opacity-70 hover:bg-blue-300">
          Confirm
        </Button>
        <Button
          onClick={handleClose}
          className="cancel-btn sm:w-[100px] w-full text-textColor-100 uppercase text-[10px]  disabled:bg-transparent bg-transparent rounded-md  hover:bg-transparent ">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
