import React, {useEffect, useState} from 'react';
import './styles.scss';
import {PrivateHeader} from '../../components';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import form, {
  getFormUiSuccess,
  submitFormRequest,
} from '../../redux/slicers/form';
import {CustomDispatch} from '../../helpers';
import {removeFormData} from '../../redux/slicers/offlineData';
import {ClipLoader} from 'react-spinners';
import Icons from '../../theme/icons';
import Cookies from 'js-cookie';
const PrivateSharedLayout = ({children}) => {
  const setTokenCookie = token => {
    Cookies.set('access_token', token, {
      expires: 1 / 96,
      secure: true,
      sameSite: 'Strict',
    });
  };

  const refreshTokenExpiry = () => {
    const token = Cookies.get('access_token');
    if (token) {
      setTokenCookie(token); // Reset expiry time
    }
  };

  const activityEvents = [
    'click',
    'mousemove',
    'keydown',
    'scroll',
    'touchstart',
  ];
  const setupActivityListeners = () => {
    activityEvents.forEach(event => {
      document.addEventListener(event, refreshTokenExpiry);
    });
  };

  useEffect(() => {
    setupActivityListeners();
    return () => {
      // Clean up listeners on unmount
      activityEvents.forEach(event => {
        document.removeEventListener(event, refreshTokenExpiry);
      });
    };
  }, []);

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    // Dispatch the reset action on URL change
    dispatch(getFormUiSuccess(''));
  }, [location, dispatch]);
  const [submitForm, submitFormLoader] = CustomDispatch(submitFormRequest);

  const {isOnline, formsData} = useSelector(state => state.offline);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   const submitOfflineData = async () => {
  //     if (isOnline && formsData && !submitFormLoader) {
  //       setIsLoading(true); // Set loading state to true
  //       // Only run if not already submitting
  //       const formKeys = Object.keys(formsData);

  //       for (const key of formKeys) {
  //         const formData = formsData[key];
  //         console.log(key, formsData, 'submitting form data for key');
  //         try {
  //           await submitForm({
  //             payload: formData,
  //             pathParams: key,
  //             logic(res) {
  //               console.log(key, res, 'submitting form data for key');
  //               dispatch(removeFormData(key)); // Remove form data after successful submission
  //             },
  //             queryParams: {
  //               notSaveLocal: true,
  //             },
  //           });
  //         } catch (error) {
  //           console.error(`Error submitting form data for ${key}:`, error);
  //           // Handle error here (retry logic, logging, etc.)
  //         }
  //       }
  //       setIsLoading(false); // Set loading state
  //     }
  //   };

  //   // Trigger the submission process when network is restored
  //   submitOfflineData();
  // }, [isOnline]);

  useEffect(() => {
    if (!isOnline) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Re-enable scrolling when online
    }

    return () => {
      document.body.style.overflow = ''; // Cleanup when component unmounts or when `isOnline` changes
    };
  }, [isOnline]);

  return (
    <section className="private-wrapper">
      {/* {isLoading && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-[#D9D9D933] backdrop-blur-sm flex flex-col items-center justify-center gap-3 px-4">
          <ClipLoader color={'#004078'} loading={true} size={150} />
          <p className="text-[32px] font-medium text-[#004078] text-center">
            Submitting offline data, please wait...
          </p>
        </div>
      )} */}
      {!isOnline && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-[#D9D9D933] backdrop-blur-sm flex flex-col items-center justify-center gap-3 px-4">
          <div className="flex justify-start w-[80%]">
            <div className="flex items-center justify-center w-[50px] bg-[#DD1717] p-3">
              <Icons.TriangleAlert width={40} height={40} />
            </div>
            <div className="flex flex-col justify-start p-3 bg-white-100">
              <h2 className="border-b border-[#DD1717] w-full text-[#DD1717] font-bold md:text-[24px] text-[16px]">
                WARNING
              </h2>
              <p className="text-[#DD1717] font-regular md:text-[24px] text-[16px]">
                Connection Lost - Please ensure you have an active internet
                connection. The system will attempt to reconnect regularly. If
                you have additional questions, please contact your system
                administrator.
              </p>
            </div>
          </div>
        </div>
      )}
      <PrivateHeader />
      {children}
    </section>
  );
};

export default PrivateSharedLayout;
