import {createSlice} from '@reduxjs/toolkit';

const MembersReducer = createSlice({
  name: 'members',
  initialState: {
    members: [],
    roles: [],
  },
  reducers: {
    getRolesRequest() {},
    getRolesSuccess(state, action) {
      state.roles = action.payload;
    },

    getMembersRequest() {},
    getMembersSuccess(state, action) {
      state.members = action.payload;
    },

    addMembersRequest() {},
    addMembersSuccess(state, action) {},

    deleteMembersRequest() {},
    deleteMembersSuccess(state, action) {},

    editMembersRequest() {},
    editMembersSuccess(state, action) {},
  },
});

export const {
  getMembersRequest,
  getMembersSuccess,
  addMembersRequest,
  addMembersSuccess,
  deleteMembersRequest,
  deleteMembersSuccess,
  editMembersRequest,
  editMembersSuccess,
  getRolesRequest,
  getRolesSuccess,
} = MembersReducer.actions;

export default MembersReducer.reducer;
