import {createSlice} from '@reduxjs/toolkit';

const OfflineReducer = createSlice({
  name: 'offline',
  initialState: {
    formsData: {},
    formsUi: {},
    isOnline: navigator.onLine,
  },
  reducers: {
    // SAVE FORM DATA
    saveFormDataLocally(state, action) {
      console.log(action.payload, 'Payload test');
      let temp = {...state.formsData};
      const {key, data} = action.payload;
      temp[key] = data;
      state.formsData = temp;
    },
    // SAVE FORM UI
    saveFormUiLocally(state, action) {
      let temp = {...state.formsUi};
      const {key, data} = action.payload;
      temp[key] = data;
      state.formsUi = temp;
    },

    //CAHNGE ONLINE STATUS
    setOnlineStatus(state, action) {
      state.isOnline = action.payload;
    },

    // REMOVE FORM DATA
    removeFormData(state, action) {
      let temp = {...state.formsData};
      console.log(temp, action.payload, 'submitting form data for key');

      delete temp[action.payload];
      console.log(temp, action.payload, 'submitting form data for key');

      state.formsData = temp;
    },
  },
});

export const {
  saveFormDataLocally,
  saveFormUiLocally,
  setOnlineStatus,
  removeFormData,
} = OfflineReducer.actions;

export default OfflineReducer.reducer;
