import {call, fork, put, take} from 'redux-saga/effects';
import {
  callRequest,
  CREATE_COMPLIANCE_RULE,
  DELETE_COMPLIANCE_RULE,
  EDIT_COMPLIANCE_RULE,
  GET_COMPLIANCE_RULES,
} from '../../config/web-service';
import {ALERT_TYPES} from '../../constants';
import {toastAlert} from '../../utils';
import {
  createComplianceRuleRequest,
  createComplianceRuleSuccess,
  deleteComplianceRuleRequest,
  deleteComplianceRuleSuccess,
  editComplianceRuleRequest,
  editComplianceRuleSuccess,
  getComplianceRulesRequest,
  getComplianceRulesSuccess,
} from '../slicers/rules';

// GET COMPLIANCE RULES
function* getComplianceRules() {
  while (true) {
    const {
      payload: {payload, queryParams, responseCallback},
    } = yield take(getComplianceRulesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_COMPLIANCE_RULES,
        payload,
        queryParams,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(getComplianceRulesSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        console.log(response, response.response_data?.non_field_errors?.[0]);
        toastAlert(
          response.response_data?.non_field_errors?.[0],
          ALERT_TYPES.ERROR,
        );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// CREATE COMPLIANCE RULE
function* createComplianceRule() {
  while (true) {
    const {
      payload: {payload, responseCallback},
    } = yield take(createComplianceRuleRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CREATE_COMPLIANCE_RULE,
        payload,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(createComplianceRuleSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        console.log(response, response.response_data?.non_field_errors?.[0]);
        toastAlert(
          response.response_data?.non_field_errors?.[0],
          ALERT_TYPES.ERROR,
        );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// CREATE COMPLIANCE RULE
function* editComplianceRule() {
  while (true) {
    const {
      payload: {payload, pathParams, responseCallback},
    } = yield take(editComplianceRuleRequest.type);
    try {
      const response = yield call(callRequest, {
        url: EDIT_COMPLIANCE_RULE,
        payload,
        pathParams,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(editComplianceRuleSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        console.log(response, response.response_data?.non_field_errors?.[0]);
        toastAlert(
          response.response_data?.non_field_errors?.[0],
          ALERT_TYPES.ERROR,
        );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//DELETE RULE
function* deleteComplianceRule() {
  while (true) {
    const {
      payload: {payload, pathParams, responseCallback},
    } = yield take(deleteComplianceRuleRequest.type);
    try {
      const response = yield call(callRequest, {
        url: DELETE_COMPLIANCE_RULE,
        payload,
        pathParams,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(deleteComplianceRuleSuccess(response.response_data));
      } else {
        responseCallback?.(false, response);
        console.log(response, response.response_data?.non_field_errors?.[0]);
        toastAlert(
          response.response_data?.non_field_errors?.[0],
          ALERT_TYPES.ERROR,
        );
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield fork(getComplianceRules);
  yield fork(createComplianceRule);
  yield fork(editComplianceRule);
  yield fork(deleteComplianceRule);
}
