import {createSlice} from '@reduxjs/toolkit';

const FormReducer = createSlice({
  name: 'form',
  initialState: {
    formPayload: {},
    formUiJson: {},
    todaysLogs: [],
  },
  reducers: {
    getPayloadRequest() {},
    getPayloadSuccess(state, action) {
      state.formPayload = action.payload;
    },
    submitFormRequest() {},
    submitFormSuccess(state, action) {
      //   state.technicians = [...action.payload];
    },
    getFormUiRequest() {},
    getFormUiSuccess(state, action) {
      console.log(action.payload, 'formSchema');
      state.formUiJson = action.payload;
    },
    getTodaysLogsRequest() {},
    getTodaysLogsSuccess(state, action) {
      state.todaysLogs = action.payload;
    },
    updateTodaysLogRequest() {},
    updateTodaysLogSuccess(state, action) {},
  },
});

export const {
  getPayloadRequest,
  getPayloadSuccess,
  submitFormRequest,
  submitFormSuccess,
  getFormUiRequest,
  getFormUiSuccess,
  // getShiftsRequest,
  // getShiftsSuccess,
  getTodaysLogsRequest,
  getTodaysLogsSuccess,
  updateTodaysLogRequest,
  updateTodaysLogSuccess,
} = FormReducer.actions;

export default FormReducer.reducer;
