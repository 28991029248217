import {Grid} from '@mui/material';
import {Button, Switch} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {CreateComplianceRuleModal, Loader} from '../../../components';
import {FORM_ROUTE} from '../../../constants';
import {CustomDispatch} from '../../../helpers';
import {
  deleteComplianceRuleRequest,
  editComplianceRuleRequest,
  getComplianceRulesRequest,
} from '../../../redux/slicers/rules';
import Icons from '../../../theme/icons';
import {replaceValInString} from '../../../utils';
import './styles.scss';

const ComplianceRules = () => {
  //SATES
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  //CONST VALS
  const navigate = useNavigate();
  const RULE_TYPES = [
    {label: 'Start Time', value: 'start_time'},
    {label: 'End Time', value: 'end_time'},
    {label: 'Submission Limit', value: 'submission_limit'},
  ];

  const INTERVALS = [
    {label: 'Hourly', value: 'hourly'},
    {label: '2 hours', value: '2_hours'},
    {label: '6 hours', value: '6_hours'},
    {label: '12 hours', value: '12_hours'},
    {label: 'Daily', value: 'daily'},
    {label: 'Weekly', value: 'weekly'},
  ];

  //REDUX DATA
  const {procedures} = useSelector(state => state.general);
  const {isOnline} = useSelector(state => state.offline);
  const {data} = useSelector(state => state.user);
  const {rules} = useSelector(state => state.rules);

  //CUSTOM DISPATCH
  const [getRules, rulesLoader] = CustomDispatch(getComplianceRulesRequest);
  const [deleteRule, deleteLoader] = CustomDispatch(editComplianceRuleRequest);

  //HANDLERS
  const createModalOpenHandler = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const handleEdit = data => {
    setSelectedData(data);
    setIsCreateModalOpen(true);
  };
  const handleStatusChange = rule => {
    let newStatus = rule.status === 0 ? 1 : 0;
    deleteRule({
      pathParams: `${rule?.id}/`,
      payload: {
        ...rule,
        status: newStatus,
      },
      logic() {
        getRules({queryParams: {}});
      },
    });
  };
  //HOOKS
  useEffect(() => {
    if (data?.id)
      getRules({
        queryParams: {},
        logic() {
          setIsFirstLoad(false);
        },
      });
  }, [data]);
  return (
    <section className="dashboard mt-[69px] relative ">
      <div className="absolute top-[0] w-full flex items-center justify-between pl-7 pr-20 py-[11px] bg-grey-100 border-b-[1px] border-solid border-grey-200 z-[1]">
        <div className="flex items-center justify-start gap-[25px]">
          <Icons.LeftChevron
            className="cursor-pointer icon"
            onClick={() => navigate('/')}
          />
          <p className="font-h2 uppercase ">Compliance Rules</p>
        </div>
      </div>
      {rulesLoader && isFirstLoad ? (
        <Loader height="80vh" />
      ) : (
        <div className="dashboard-content px-[20px] md:px-[40px] py-[20px] pt-[70px] ">
          <div className="flex items-center justify-end mb-[30px]">
            <Button
              className="custom-form-btn primary"
              onClick={createModalOpenHandler}>
              Add Rule
            </Button>
          </div>
          <Grid
            container
            columnSpacing={{xs: '40px', xl: '90px'}}
            rowSpacing={{xs: '40px', xl: '60px'}}>
            {rules?.map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
                <div className="card-wrapper p-[15px] border-[1px]  border-solid border-grey-200 rounded-[3px]   hover:border-blue-200">
                  <div className="flex items-start justify-between gap-4">
                    <div className="flex items-center justify-start gap-[10px] mb-[20px]">
                      <Icons.ProcedureIcon width={30} height={30} />
                      <p
                        onClick={() => handleEdit(item)}
                        className="font-h4 text-blue-300 hover:text-blue-200 cursor-pointer">
                        {item.form_name}
                      </p>
                    </div>
                    <Switch
                      checked={item.status === 0}
                      disabled={deleteLoader}
                      onChange={() => handleStatusChange(item)}
                    />
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <p className="font-h4 text-[14px] text-[#000] cursor-pointer">
                      Rule Type:
                    </p>
                    <p className="font-paragraph text-[14px]">
                      {item.rule_type}
                    </p>
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <p className="font-h4 text-[14px] text-[#000] cursor-pointer">
                      Rule Value:
                    </p>
                    <p className="font-paragraph text-[14px]">
                      {item.rule_value}
                    </p>
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <p className="font-h4 text-[14px] text-[#000] cursor-pointer">
                      Company:
                    </p>
                    <p className="font-paragraph text-[14px]">
                      {item.company_name}
                    </p>
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <p className="font-h4 text-[14px] text-[#000] cursor-pointer">
                      Interval:
                    </p>
                    <p className="font-paragraph text-[14px]">
                      {item.schedule_interval}
                    </p>
                  </div>
                  <div className="flex items-start justify-start gap-2">
                    <p className="font-h4 text-[14px] text-[#000] cursor-pointer whitespace-nowrap">
                      Alert Message:
                    </p>
                    <p className="font-paragraph text-[14px] line-clamp-2 overflow-hidden text-ellipsis">
                      {item.alert_message}
                    </p>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <CreateComplianceRuleModal
        open={isCreateModalOpen}
        handleClose={() => {
          createModalOpenHandler();
          setSelectedData(null);
        }}
        successHandler={() => {
          createModalOpenHandler();
          getRules({queryParams: {}});
          setSelectedData(null);
        }}
        RULE_TYPES={RULE_TYPES}
        INTERVALS={INTERVALS}
        selectedData={selectedData}
      />
    </section>
  );
};

export default ComplianceRules;
