// src/components/NetworkStatusListener.jsx

import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setOnlineStatus} from '../../../redux/slicers/offlineData';

const NetworkStatusListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateNetworkStatus = () => {
      dispatch(setOnlineStatus(navigator.onLine));
    };

    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    // Set initial status on mount
    updateNetworkStatus();

    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, [dispatch]);

  return null;
};

export default NetworkStatusListener;
