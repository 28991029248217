import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Row,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CustomDispatch} from '../../../helpers';
import {
  getPDfJsonSuccess,
  getProceduresRequest,
  searchReportRequest,
  searchReportSuccess,
} from '../../../redux/slicers/general';
import {Icons} from '../../../theme';
import {MultiSelect} from '../../common';
import PDFReport from '../../common/pdfGenerator';
import SearchTable from '../searchTable';
import './styles.scss';
const SearchDrawer = ({open, handleClose}) => {
  //STATES
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);

  //REDUX DATA
  const procedures = useSelector(state => state.general.procedures);
  const reportJson = useSelector(state => state.general.reportJson);
  const user = useSelector(state => state.user.data);
  const searchResults = useSelector(state => state.general.searchResults);

  //CUSTOM DISPATCH
  const [getProcedures, proceduresLoader] =
    CustomDispatch(getProceduresRequest);
  const [searchReport, searchLoader] = CustomDispatch(searchReportRequest);

  //CONST VALS
  const [form] = Form.useForm();
  const disabledStartDate = current => {
    return current && current > dayjs().endOf('day');
  };
  const disabledEndDate = current => {
    if (!startDate) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current <= startDate || current > dayjs().endOf('day'));
  };
  const dispatch = useDispatch();
  //HANDLERS
  const onStartDateChange = date => {
    setStartDate(date);
    form.setFieldsValue({endDate: null});
    setEndDate(null);
  };

  const onEndDateChange = date => {
    setEndDate(date);
  };

  const handleFormFinsh = values => {
    // console.log(values);
    const formatDate = date => date.toISOString().split('T')[0]; // Function to format date to YYYY-MM-DD

    const queryParams = {
      procedure: values.procedure,
      start_date: values?.start_date
        ? formatDate(new Date(values?.start_date))
        : null,
      end_date: values?.end_date
        ? formatDate(new Date(values?.end_date))
        : null,
      company_id: user.companyId,
    };

    console.log(queryParams, 'queryParams');
    searchReport({queryParams: queryParams});
  };

  //HOOKS
  useEffect(() => {
    if (open) {
      getProcedures();
    }
    dispatch(searchReportSuccess([]));
  }, [open]);
  console.log({selectedRows});
  return (
    <Drawer
      width={680}
      open={open}
      closeIcon={false}
      mask={false}
      zIndex={1}
      destroyOnClose
      className="common-drawer"
      // onClose={handleClose}
      // maskClosable
    >
      <Row gutter={[0, 15]} className="mt-[69px]">
        <Col span={24}>
          <div className="flex items-center justify-between ">
            <p className="font-h4">Search Records</p>
            <Icons.CloseIcon
              height={16}
              width={16}
              onClick={() => {
                handleClose();
                form.resetFields();
                dispatch(getPDfJsonSuccess(''));
                dispatch(searchReportSuccess([]));
              }}
              className="cursor-pointer"
            />
          </div>
        </Col>
        <Col span={24}>
          <Form form={form} onFinish={handleFormFinsh}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <p className="font-h4">Document</p>
              </Col>
              <Col span={24}>
                <Form.Item name={'procedure'} className="m-0">
                  <Select
                    options={procedures.map(x => {
                      return {label: x.title, value: x.id};
                    })}
                    placeholder="Select a procedure"
                    onSelect={value => setSelectedProcedure(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="flex items-center justify-start gap-[20px]">
                  <p className="font-h4">Date / Date Range</p>
                  <p className="font-paragraph-sm">optional</p>
                </div>
              </Col>
              <Col span={12}>
                <p className="font-subtext mb-[10px]">Start</p>
                <Form.Item className="m-0" name={'start_date'}>
                  <DatePicker
                    disabledDate={disabledStartDate}
                    onChange={onStartDateChange}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <p className="font-subtext mb-[10px]">End</p>
                <Form.Item className="m-0" name={'end_date'}>
                  <DatePicker
                    disabledDate={disabledEndDate}
                    onChange={onEndDateChange}
                    disabled={!startDate}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className="font-paragraph-sm">
                  Use Start field if searching one date. Skip dates if you want
                  all dates available
                </p>
              </Col>
              <Col span={24}>
                <Button
                  // disabled={selectedProcedures.length < 1}
                  className="w-full custom-form-btn primary"
                  type="submit"
                  htmlType="submit"
                  loading={searchLoader}>
                  SEARCH
                </Button>
              </Col>
              <Divider className="border-textColor-200 m-0" />
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <p className="font-h4">Search Results</p>
        </Col>
        <Col span={24}>
          <div className="p-[10px] rounded-t-[8px] bg-white-100 flex items-center justify-between gap-[10px]">
            <span className="font-table flex items-center justify-start gap-[10px]">
              {searchResults.length > 0 && (
                <>
                  <p className="font-[500]">Found:</p> {searchResults.length}{' '}
                  Documents
                </>
              )}
            </span>
            <div className="flex items-center justify-start gap-[10px]">
              {/* <Icons.PdfIcon
                className="cursor-pointer"
                height={20}
                width={20}
              /> */}
              <PDFReport
                data={reportJson}
                selectedItems={selectedRows}
                selectedProcedure={selectedProcedure}
              />
              <PDFReport
                data={reportJson}
                print={true}
                selectedItems={selectedRows}
                selectedProcedure={selectedProcedure}
              />
              {/* <Icons.PrintIcon
                className="cursor-pointer"
                height={20}
                width={20}
              /> */}
            </div>
          </div>
          <SearchTable selected={selectedRows} setSelected={setSelectedRows} />
        </Col>
      </Row>
    </Drawer>
  );
};
export default SearchDrawer;
