import React, {useEffect} from 'react';
import './styles.scss';
import {useSelector} from 'react-redux';
import Icons from '../../theme/icons';
function AuthSharedLayout({children}) {
  const {isOnline} = useSelector(state => state.offline);
  useEffect(() => {
    if (!isOnline) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Re-enable scrolling when online
    }

    return () => {
      document.body.style.overflow = ''; // Cleanup when component unmounts or when `isOnline` changes
    };
  }, [isOnline]);
  return (
    <section className="auth-wrapper">
      {!isOnline && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-[#D9D9D933] backdrop-blur-sm flex flex-col items-center justify-center gap-3 px-4">
          <div className="flex justify-start w-[80%]">
            <div className="flex items-center justify-center w-[50px] bg-[#DD1717] p-3">
              <Icons.TriangleAlert width={40} height={40} />
            </div>
            <div className="flex flex-col justify-start p-3 bg-white-100">
              <h2 className="border-b border-[#DD1717] w-full text-[#DD1717] font-bold md:text-[24px] text-[16px]">
                WARNING
              </h2>
              <p className="text-[#DD1717] font-regular md:text-[24px] text-[16px]">
                Connection Lost - Please ensure you have an active internet
                connection. The system will attempt to reconnect regularly. If
                you have additional questions, please contact your system
                administrator.
              </p>
            </div>
          </div>
        </div>
      )}
      {children}
    </section>
  );
}

export default AuthSharedLayout;
