import _ from 'lodash';

//PROCEDURES

export const manipulateProcedureData = data => {
  if (_.isEmpty(data)) return {};
  let procedure = {};
  procedure['id'] = data?.key ?? 0;
  procedure['title'] = data?.title ?? '';
  procedure['form_id'] = data?.id ?? '';
  procedure['description'] = data?.description ?? '';
  procedure['status'] = data?.status ?? '';
  return procedure;
};
export const manipulateProceduresList = data => {
  if (_.isEmpty(data)) return [];

  let procedures = [];

  data?.forEach(item => {
    procedures.push(manipulateProcedureData(item));
  });

  return procedures;
};

//TECHNICIANS

export const manipulateTechnicianData = data => {
  if (_.isEmpty(data)) return {};
  let technician = {};
  technician['value'] = data?.id?.toString() ?? 0;
  technician['label'] = data?.first_name + ' ' + data?.last_name ?? '';

  return technician;
};
export const manipulateTechniciansList = data => {
  if (_.isEmpty(data)) return [];

  let technicians = [];

  data?.forEach(item => {
    technicians.push(manipulateTechnicianData(item));
  });

  return technicians;
};

export const manipulateFormValueData = data => {
  if (_.isEmpty(data)) return {};
  let formValue = {};
  formValue['value'] = data?.value?.toString() ?? '';
  formValue['label'] = data?.value ?? '';

  return formValue;
};
export const manipulateFormValuesList = data => {
  if (_.isEmpty(data)) return [];

  let formValues = [];

  data?.forEach(item => {
    formValues.push(manipulateFormValueData(item));
  });

  return formValues;
};

export const manipulateShiftData = data => {
  if (_.isEmpty(data)) return {};
  let shift = {};
  shift['value'] = data?.id?.toString() ?? 0;
  shift['label'] = data?.name ?? '';

  return shift;
};
export const manipulateShiftsList = data => {
  if (_.isEmpty(data)) return [];

  let shifts = [];

  data?.forEach(item => {
    shifts.push(manipulateShiftData(item));
  });

  return shifts;
};

export const parseJson = data => {
  if (_.isEmpty(data)) return {};

  return JSON.parse(data);
};

export const manipulateSearchResultData = data => {
  if (_.isEmpty(data)) return {};

  let result = {};
  result['key'] = data?.reportId ?? '';
  result['id'] = data?.key ?? '';
  result['ref'] = data?.reportId ?? 0;
  result['name'] = data?.name ?? '';
  result['submittedBy'] = data?.submittedBy ?? '';
  result['submittedOn'] = data?.date ?? '';
  result['status'] = data?.status ?? '';

  return result;
};

export const manipulateSearchResultsList = data => {
  if (_.isEmpty(data)) return [];

  let results = [];

  data?.forEach(item => {
    results.push(manipulateSearchResultData(item));
  });

  return results;
};
