import React, {useState} from 'react';
import {Table} from 'antd';
import './styles.scss';
import {useSelector} from 'react-redux';

const columns = [
  {
    title: 'Ref#',
    dataIndex: 'ref',
    width: '70px',
    render: text => <p className="font-table">{text}</p>,
    sorter: (a, b) => b.ref - a.ref,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: text => <p className="font-table">{text}</p>,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Submitted by',
    dataIndex: 'submittedBy',
    render: text => <p className="font-table">{text}</p>,
    sorter: (a, b) => a.submittedBy.localeCompare(b.submittedBy),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: text => <p className="font-table">{text}</p>,
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: 'Date Submitted',
    dataIndex: 'submittedOn',
    render: text => <p className="font-table">{text}</p>,
    sorter: (a, b) => new Date(a.submittedOn) - new Date(b.submittedOn),
  },
];

const SearchTable = ({selected, setSelected}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchResults = useSelector(state => state.general.searchResults);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelected(newSelectedRowKeys);
    console.log(selected, selectedRows, newSelectedRowKeys);
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  return (
    <div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResults ?? []}
        pagination={false}
        scroll={{y: 215, x: 530}}
        className="search-table"
      />
    </div>
  );
};

export default SearchTable;
