import React, {useState} from 'react';
import {Select, Divider} from 'antd';

const {Option} = Select;

const MultiSelect = ({
  value = [],
  onChange,
  options,
  placeholder,
  loading,
  labelProp = 'label',
  valueProp = 'value',
}) => {
  const handleSelectChange = selected => {
    if (selected.includes('selectAll')) {
      const allValues = options.map(option => option?.[valueProp]);
      onChange(allValues);
    } else if (selected.includes('deselectAll')) {
      onChange([]);
    } else {
      onChange(selected);
    }
  };

  const handleSelectAll = () => {
    const allValues = options.map(option => option?.[valueProp]);
    onChange(allValues);
  };

  const handleDeselectAll = () => {
    onChange([]);
  };

  const onSearch = value => {};

  // Filter `option.children` match the user type `input`
  const filterOption = (input, option) =>
    (option.children ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      loading={loading}
      mode="multiple"
      onSearch={onSearch}
      showSearch
      filterOption={filterOption}
      optionFilterProp="children"
      value={value}
      onChange={handleSelectChange}
      placeholder={placeholder}
      dropdownRender={menu => (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 8,
            }}>
            <span
              onClick={handleSelectAll}
              style={{cursor: 'pointer', color: '#1890ff'}}>
              Select All
            </span>
            <Divider type="vertical" />
            <span
              onClick={handleDeselectAll}
              style={{cursor: 'pointer', color: '#1890ff'}}>
              Deselect All
            </span>
          </div>
          <Divider style={{margin: 0}} />
          {menu}
        </>
      )}>
      {options.map(option => (
        <Option
          key={option[valueProp]}
          value={option[valueProp]}
          label={option[labelProp]}>
          {option[labelProp]}
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelect;
